import * as React from "react";
import { useEffect, useState } from "react";
import "./home.css";
import axios from "axios";
import Loader from "./Loader";

export default function Home() {
  const [data, setData] = useState([]);
  const [stucount, setStucount] = useState(0);
  const [count, setCount] = useState(0);
  const [staff, setStaff] = useState([]);
  const [staffCount, setStaffCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // turn on loader
    setLoading(true);
  
    axios
      .get("https://app.conext.in/mdsu/")
      .then((response) => {
        setData(response.data.students);
        setStucount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  
    axios
      .get("https://app.conext.in/mdcstaff/")
      .then((response) => {
        setStaff(response.data.staffs);
        setStaffCount(response.data.staffcount);
      })
      .catch((error) => {
        console.log(error);
      });

      setLoading(false);
  }, []);
  
  useEffect(() => {
    setCount(stucount + staffCount);
  }, [stucount, staffCount]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response1 = await axios.get("https://conext.in/renewals/api/check_expired_items_in_a_week");
        console.log(response1.data); 

        const response2 = await axios.get("https://conext.in/renewals/api/check_expired_items_tomorrow");
        console.log(response2.data); 

        const response4 = await axios.get("https://conext.in/custom_users/api/staff_birthday_emails/");
        console.log(response4.data); 

        const response5 = await axios.get("https://conext.in/custom_users/api/student_birthday_emails/");
        console.log(response5.data); 

        const response3 = await axios.get("https://conext.in/renewals/api/check_expired_and_not_renewed_items");
        console.log(response3.data); 

      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);
  

  return (
    <body className="body">
      {/* if loader is true show loader else the content  */}

      <div className="modal">
        <div className="header">
          <span className="title">
            {" "}
            It's birthday <br></br>for {count} of us today..!{" "}
          </span>
        </div>
        {/* display today date here */}
        <div className="date-div">
          <span className="date-text">{new Date().toDateString()}</span>
        </div>
        {/* wish from college here if count not eqal zero*/}
        {count !== 0 ? (
          <div className="wish-div">
            <span className="wish-text">
              Malabar dental college wishes you many
              many happy returns of the day..!
            </span>
          </div>
        ) : (
          <div className="wish-div">
            <span className="wish-text">
              During this date, no students or staff have birthdays
            </span>
          </div>
        )}
        {loading ? (
          <div className="card loader">
            {" "}
            <Loader />{" "}
          </div>
        ) : (
          <div className="content">
            {data.map((item) => (
              <div className="card">
                <div className="row">
                  <div className="col-4">
                    {/* if img loaded full show img else show loading  */}
                    {item.profile_pic ? (
                      <img
                        src={"https://app.conext.in" + item.profile_pic}
                        alt="profile pic"
                        className="profile-pic"
                      />
                    ) : (
                      <Loader />
                    )}
                  </div>
                  <div className="col-8">
                    <h5 className="card-title">{item.student_name} </h5>
                    <p className="card-text">
                      Age:{" "}
                      {new Date().getFullYear() -
                        new Date(item.dob).getFullYear()}
                    </p>
                    <p className="card-text">Course: {item.course}</p>
                    <p className="card-text">Batch: {item.batch_year}</p>
                  </div>
                </div>
              </div>
            ))}

            {/* staff data here */}
            {staff.map((item) => (
              <div className="card">
                <div className="row">
                  <div className="col-4">
                    {/* if img loaded full show img else show loading  */}
                    {item.profile_pic ? (
                      <img
                        src={"https://app.conext.in" + item.profile_pic}
                        alt="profile pic"
                        className="profile-pic"
                      />
                    ) : (
                      <Loader />
                    )}
                  </div>
                  <div className="col-8">
                    <h5 className="card-title">{item.staff_name} </h5>
                    <p className="card-text">
                      Age:{" "}
                      {new Date().getFullYear() -
                        new Date(item.dob).getFullYear()}
                    </p>
                    <p className="card-text">{item.designation}</p>
                    <p className="card-text">Dep: {item.department}</p>
                  </div>
                </div>
              </div>
            ))}
            {/* href button */}
            <div className="btn-center">
              <button className="ok-button">
                <a
                  href="https://mdc.conext.in/birthdays.html"
                  className="btn btn-primary"
                >
                  Add your Birthday{" "}
                </a>{" "}
              </button>
            </div>
            <p className="text-center-p">
              Connect V1 | IT department 
            </p>
          </div>
        )}
      </div>
    </body>
  );
}
